// calendar.js

// IIFE so that we can use $ instead of jQuery
const CalendarPage = function($) {
  let calendarEntries;

  function init() {
    if ($('#calendar').length > 0) {
      calendarEntries = JSON.parse(JSON.stringify(params.calendarEntries));
      loadCalendar();
    }
  }

  function loadCalendar() {
    $('#calendar').fullCalendar({
      // put your options and callbacks here
      header: {
        left: '',
        center: '',
        right: ''
      },
      views: {
        listMonth: { buttonText: 'List' },
        month: { buttonText: 'Calendar' }
      },
      listDayFormat: 'dddd, MMM D, YYYY',
      listDayAltFormat: false,
      eventAfterRender: function(event, element) {
        let index = element.parent().index();
        let colspan = element.parent().prop('colspan');

        let n = 0;
        while (n < colspan) {
          element.closest('.fc-week').find('.fc-bg td').eq(index + n).css('background-color', '#FFEA91');
          n++;
        }
      },
      navLinks: true, // can click day/week names to navigate views
      editable: false,
      eventLimit: true, // allow "more" link when too many events
      events: calendarEntries,
      eventBackgroundColor: '#FFEA91',
      fixedWeekCount: false
    });
  }

  init();

}(jQuery)
