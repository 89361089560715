// login-form.js

// IIFE so that we can use $ instead of jQuery
const LoginForm = function($) {

  function init() {
    if ($('.gf_login_form').length > 0) {
      setPlaceholders();
    }
  }

  function setPlaceholders() {
    $('.gf_login_form input[type="text"]').attr('placeholder', 'Username');
    $('.gf_login_form input[type="password"]').attr('placeholder', 'Password');
  }

  init();

}(jQuery)
