// map.js

// IIFE so that we can use $ instead of jQuery
const MemberMap = function($) {
  let $infoBox;

  function init() {
    $('.marker-trigger').on('click', function() {
      let memberId = $(this).data('member-id');
      if (memberId in markers) {
        console.log(locations[memberId]);
        google.maps.event.trigger(markers[memberId], 'click');
      }
    });
    /*
    if ($('.member-map').length > 0) {
      // Init popovers
      $('[data-toggle="popover"]').popover({ trigger: "manual" , html: true, animation:false})
        .on("mouseenter", function () {
          var _this = this;
          $(this).popover("show");
          $(".popover").on("mouseleave", function () {
            $(_this).popover('hide');
          });
        }).on("mouseleave", function () {
          var _this = this;
          setTimeout(function () {
            if (!$(".popover:hover").length) {
              $(_this).popover("hide");
            }
          }, 300);
      });

      $infoBox = $('.member-map .infobox');
      positionMarkers();
    }
    */
  }

  function positionMarkers() {
    $('.marker').each(function(){
      let scale = $('img.map').prop('width')/$('img.map').prop('naturalWidth');
      $(this).css('left', (scale)*$(this).data('x'));
      $(this).css('top', (scale)*$(this).data('y'));

      $(this).click(function(){
        let memberData = $(this).data('member');

        $('.image img', $infoBox).attr('src', $(this).data('image')).attr('alt', memberData.meta.organization_name);
        $('.organization', $infoBox).html(
          `<a href="/members/${memberData.user_login}">${memberData.meta.organization_name}</a>`
        );
        $('.address', $infoBox).html(
          `${memberData.meta.address}<br />${memberData.meta.city}, ${memberData.meta.province}, ${memberData.meta.postal_code}`
        );
        $('.acreage .value', $infoBox).text(memberData.meta.acreage);
        $('.population .value', $infoBox).text(memberData.meta.population);
        $('.website a', $infoBox).attr('href', memberData.meta.website).text(memberData.meta.website);
        $infoBox.removeClass('d-none');
      });
    });
  }

  init();

}(jQuery)
